import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { WINDOW } from '@ng-web-apis/common';
import { DataWindow, SiteConfigService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import {
  FundId,
  FundShareClassId,
  GlobalId,
  SegmentId,
  ShareClassCode,
  PersonalisationFirmDataDto,
  PersonalisationFundDocs,
  PersonalisationPersonalData,
  PersonalisationPersonalDataDto,
  PersonalisationPersonalDataProduct,
  PersonalisationPersonalDataWithProducts,
  PersonalisationProductRelationship,
  PersonalisationPersonalDataDocuments,
  PersonalisationPersonalDataProductDto,
  PersonalisationToken,
  PlatformQueryParam,
  BrightcoveVideoDto,
  PersonalisationLocalSalesTeam,
  PersonalisationFavoriteDataDTO,
  PersonalisationFavoriteData,
  SalesOffice,
} from '@types';
import { Logger } from '@utils/logger';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  skipWhile,
} from 'rxjs/operators';
import { USServicingComponentTypes } from '../ft-components/interactive-content/us-servicing/types/us-servicing-config.interface';
import { AppStateService } from './app-state.service';
import { IFirmProfile, ILoggedInHeaders } from './profile.interface';
import { SegmentService } from './segment.service';
import { StorageService } from './storage.service';
import { GENERIC_ID_TOKEN } from '@utils/app.constants';
import { FirmConfig } from './firm.config';
import { LaunchDarklyService } from './launch-darkly.service';
import { getFundIdAndShareclassCode } from '@utils/text/data-utils';

const logger = Logger.getLogger('PersonalisationAPIService');
export const ID_TOKEN_PARAM = 'ft_token';

export class PersonalisationMissingTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PersonalisationMissingTokenError';
  }
}

export class PersonalisationHttpError extends Error {
  code: number;
  constructor(message: string, code) {
    super(message);
    this.name = 'PersonalisationHttpError';
    this.code = code;
  }
}

// params determine what data we want returned.
const personalisationApiParams =
  'includeHeldProducts=true&includefavoriteProducts=true&includeSubscribedDocuments=true&includeSalesTeamCoverage=true';

@Injectable({
  providedIn: 'root',
})
export class PersonalisationAPIService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private rawData: PersonalisationPersonalDataDto;
  private personalData$: ReplaySubject<PersonalisationPersonalData> = new ReplaySubject<PersonalisationPersonalData>(
    1
  );
  private personalDataWithProducts$: Observable<PersonalisationPersonalDataWithProducts>;
  private firmSpecificProducts$: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1
  );

  // emit error+details instead of just boolean
  private invalidTokenError$: ReplaySubject<Error> = new ReplaySubject<Error>(
    1
  );
  private oauthToken$: BehaviorSubject<PersonalisationToken> = new BehaviorSubject<PersonalisationToken>(
    null
  );
  private firmInvestedFunds$: BehaviorSubject<
    PersonalisationPersonalDataProductDto[]
  > = new BehaviorSubject<PersonalisationPersonalDataProductDto[]>(null);

  private isActiveListUpdatedByFirm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private platformProducts: FundShareClassId[];
  private firmConfig: IFirmProfile;
  private productAvailabilityData$: Observable<PersonalisationFirmDataDto>;

  private loggedInHeaders: ILoggedInHeaders;

  constructor(
    @Inject(WINDOW) readonly windowRef: DataWindow,
    private http: HttpClient,
    private storageService: StorageService,
    private segmentService: SegmentService,
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private launchDarklyService: LaunchDarklyService
  ) {
    logger.debug('PersonalisationAPIService constructor()');
    // Listen to Personalisation Data changes for FP segment only
    combineLatest([
      this.segmentService.getCurrentSegmentId$(),
      this.getPersonalData$(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([segmentId, personalData]) => {
        delete this.windowRef.profileData.globalId;
        delete this.windowRef.profileData.expressNumber;
        if (
          segmentId === SegmentId.FINANCIAL_PROFESSIONALS &&
          personalData.identifiers?.globalId &&
          personalData.identifiers?.expressNumber
        ) {
          this.windowRef.profileData.globalId =
            personalData.identifiers.globalId;
          this.windowRef.profileData.expressNumber =
            personalData.identifiers.expressNumber;
        }
      });
  }

  // called by app module on startup
  public init(): void {
    logger.debug('PersonalisationAPIService init()');
    this.checkIfIdentified();

    // listen for token changes and get data as soon as we have one
    // NB getIdentityToken$() will emit an empty string if nothing set
    this.getIdentityToken$()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((token) => {
          if (!token) {
            // check that a token is set, and not an empty string
            // this error can be ignored, except on USServicing page where it should be logged
            this.invalidTokenError$.next(
              new PersonalisationMissingTokenError('No token set')
            );
            return false;
          }
          if (token === GENERIC_ID_TOKEN) {
            return false;
          }
          // only emit (and cause http request) if we have a token set
          return true;
        }),
        switchMap(
          (
            token: PersonalisationToken
          ): Observable<PersonalisationPersonalData> =>
            this.getTokenPersonalData$(token)
        )
      )
      .subscribe(
        this.handlePersonalisationApiResponse(),
        (error: any): void => {
          logger.debug(
            'catch rethrown error from getTokenPersonalData$()',
            error
          );
        }
      );

    this.getPersonalData$()
      .pipe(
        switchMap((personalData: PersonalisationPersonalData) => {
          return this.hasFirmSpecificFunds$(personalData);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((firmSpecificFunds) => {
        this.firmSpecificProducts$.next(firmSpecificFunds);
      });

    this.getPersonalData$()
      .pipe(
        switchMap((personalData: PersonalisationPersonalData) => {
          if (
            this.isRIAUser(personalData.localSalesTeam) &&
            !personalData.hasPersonalHeldProducts
          ) {
            // Get Firm/Team Invested Funds list only for RIA users when personalized Held Products list is empty.
            const globalId = personalData.teamInvestedFundFlag
              ? personalData.advisorTeamId
              : personalData.firmGlobalId;
            return this.getFirmInvestedFunds$(globalId);
          }
          return of([]);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (firmInvestedFunds: PersonalisationPersonalDataProductDto[]) => {
          this.firmInvestedFunds$.next(firmInvestedFunds);
        }
      );

    // set up WithProducts data after siteconfig has been populated
    this.personalDataWithProducts$ = combineLatest([
      this.getPersonalData$(),
      this.siteConfigService.getIsPopulated$(),
      this.getFirmInvestedFundsSubject$(),
      this.firmSpecificProducts$,
    ]).pipe(
      tap(
        ([data, isPopulated, firmInvestedFunds, firmSpecificProducts]: [
          PersonalisationPersonalData,
          boolean,
          PersonalisationPersonalDataProductDto[],
          boolean
        ]): void => {
          logger.debug(
            'getPersonalDataWithProducts$()',
            isPopulated,
            data,
            firmInvestedFunds,
            firmSpecificProducts
          );
        }
      ),
      filter(
        ([data, isPopulated, firmInvestedFunds]: [
          PersonalisationPersonalData,
          boolean,
          PersonalisationPersonalDataProductDto[],
          boolean
        ]): boolean => isPopulated
      ),
      map(this.mapProducts),
      tap((data: PersonalisationPersonalDataWithProducts): void => {
        logger.debug('getPersonalDataWithProducts$() after', data);
      }),
      shareReplay(1)
    );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public callPersonalisationApiForLoggedInUser$(
    token: PersonalisationToken
  ): Observable<PersonalisationPersonalData> {
    return this.getTokenPersonalData$(token).pipe(
      tap(this.handlePersonalisationApiResponse()),
      switchMap(() => this.getPersonalData$())
    );
  }

  public getPersonalData$ = (): Observable<PersonalisationPersonalData> =>
    this.personalData$.asObservable();

  public getPersonalDataWithProducts$ = (): Observable<PersonalisationPersonalDataWithProducts> =>
    this.personalDataWithProducts$;

  public hasFirmSpecificProducts$ = (): Observable<boolean> =>
    this.firmSpecificProducts$.asObservable();

  /**
   * map personalData.ftcomRegistered as Observable.
   */
  public isRegistered$ = (): Observable<boolean> => {
    return this.personalData$.pipe(
      map(
        (personalData: PersonalisationPersonalData): boolean =>
          personalData.ftcomRegistered
      )
    );
  };

  /**
   * map personalData.parentFirmGlobalId as Observable.
   */
  public getParentFirmGlobalID$ = (): Observable<GlobalId> => {
    return this.personalData$.pipe(
      map(
        (personalData: PersonalisationPersonalData): GlobalId =>
          personalData.parentFirmGlobalId
      )
    );
  };

  /**
   * NB: this will initially return empty array, then array with fund and share class ids, then array with docs added
   * @returns an observable with funds and associated docs
   */
  public getFundData$ = (): Observable<PersonalisationFundDocs[]> =>
    this.getPersonalDataWithProducts$().pipe(
      map(
        (
          data: PersonalisationPersonalDataWithProducts
        ): PersonalisationFundDocs[] => data.products
      )
    );

  public invalidToken$ = (): Observable<Error> =>
    this.invalidTokenError$.asObservable();

  public getIdentityToken$(): Observable<PersonalisationToken> {
    return from(this.storageService.getIdentityToken());
  }

  /**
   * Check identified users only for FP users.
   * Service-center page is for FP only users.
   */
  public isIdentified$ = (): Observable<boolean> =>
    this.segmentService.getCurrentSegmentId$().pipe(
      takeUntil(this.unsubscribe$),
      switchMap(
        (segmentId: SegmentId): Observable<boolean> => {
          if (segmentId === SegmentId.FINANCIAL_PROFESSIONALS) {
            return this.getIdentityToken$().pipe(map((token) => !!token));
          }
          return of(false);
        }
      )
    );

  /**
   * Set Oauth Token
   * @param token - PersonalisationToken
   */
  public setOauthToken(token: PersonalisationToken): void {
    this.oauthToken$.next(token);
  }

  /**
   * Get Oauth Token
   */
  public getOauthToken$ = (): Observable<PersonalisationToken> =>
    this.oauthToken$.asObservable();

  /**
   * Get Firm Invested Funds
   */
  public getFirmInvestedFundsSubject$ = (): Observable<
    PersonalisationPersonalDataProductDto[]
  > => this.firmInvestedFunds$.asObservable();

  /**
   * Get Brightcove Data
   * @param videoById - Video ID
   * @param videoAccountId - Optional Brightcove Account ID
   * @returns - Brightcove Video data from API
   */
  public getBrightcoveData$(
    videoById: string,
    videoAccountId?: string
  ): Observable<BrightcoveVideoDto> {
    // https://ft-personalisation-api-dev.us-w2.cloudhub.io/api/brightcove/videobyid/6319169470112/802657821001
    let apiUrl = `/api/personalisation/brightcove/videobyid/${videoById}`;
    // UDS-1173 - Adding videoAccountId as optional API parameter this allows to cover multiple Brightcove accounts.
    if (videoAccountId) {
      apiUrl = `${apiUrl}/${videoAccountId}`;
    }
    return this.http
      .get<BrightcoveVideoDto>(apiUrl, this.getHttpOptions())
      .pipe(
        catchError((error) => {
          logger.debug('Brightcove API error:', error);
          return throwError('Something went wrong; please try again later.');
        })
      );
  }

  private handlePersonalisationApiResponse = () => (
    data: PersonalisationPersonalData
  ): void => {
    if (this.checkCountryMatches(data)) {
      logger.debug('mapped personal data', data);
      // this.launchDarklyService.updateLaunchDarklyContext(
      //   data,
      //   this.appStateService.getChannel(),
      //   this.appStateService.getEnvConfig().launchDarklyKey
      // );
      // initializing the launch darkly only for identified and logged in user for US
      if (this.appStateService.getChannel() === 'en-us') {
        this.launchDarklyService.initialize(
          this.appStateService.getChannel(),
          this.appStateService.getEnvConfig().launchDarklyKey,
          data
        );
      }

      datadogRum?.setGlobalContextProperty(
        'globalid',
        data?.identifiers?.globalId
      );
      data.isLoggedIn = !!this.loggedInHeaders;
      this.personalData$.next(data);
      logger.debug('valid token');
      this.invalidTokenError$.next(null);
    }
  };

  private checkCountryMatches(data: PersonalisationPersonalData): boolean {
    const isMatch =
      !data.salesOffice ||
      data.salesOffice === this.appStateService.getSalesOffice();
    if (!isMatch) {
      this.invalidTokenError$.next(
        new PersonalisationMissingTokenError(
          'Token does not match current site'
        )
      );
      this.storageService.removeIdentyToken();
    }
    return isMatch;
  }

  private handlePersonalisationApiError = (
    httpError: HttpErrorResponse
  ): Observable<never> => {
    logger.error('PersonalisationAPI token http error:', httpError);
    // if there ia any error in personalization api, emit it
    this.invalidTokenError$.next(
      new PersonalisationHttpError(httpError.message, httpError.status)
    );
    this.invalidateIdentity();
    // otherwise, let error get handled elsewhere
    return throwError(httpError);
  };

  public invalidateIdentity(): void {
    this.storageService.removeIdentyToken();
  }

  private checkIfIdentified(): void {
    const params: URLSearchParams = new URLSearchParams(
      this.windowRef.location.search
    );
    logger.debug('params before', params);
    const urlToken: PersonalisationToken = params.get(
      ID_TOKEN_PARAM
    ) as PersonalisationToken;
    if (urlToken) {
      this.storageService.setIdentityToken(urlToken);

      // assume that if token in url, then user must be an FP, so we force it
      this.segmentService.setSegment(SegmentId.FINANCIAL_PROFESSIONALS, true);

      // remove identity token from url for security
      params.delete(ID_TOKEN_PARAM);
      this.windowRef.history.replaceState(
        null,
        '',
        `${this.windowRef.location.pathname}?${params}${this.windowRef.location.hash}`
      );
    }
    logger.debug('params after', params);
  }

  /**
   * Returns all mapped documents
   * @param documents - Array of Documents
   */
  private mapSubscribedDocuments(
    documents: PersonalisationPersonalDataDocuments[]
  ): PersonalisationPersonalDataDocuments[] | undefined {
    if (documents?.length > 0) {
      return documents.map((doc: PersonalisationPersonalDataDocuments) => {
        return {
          ...doc,
          translatedDocumentType: this.getTranslatedDctermType(
            doc.documentType
          ),
        };
      });
    }
  }

  private getPersonalisationApiUrlForIdentityToken = (
    token: PersonalisationToken
  ): string =>
    `/api/personalisation/getClient/tokenId/${encodeURIComponent(
      token
    )}?${personalisationApiParams}`;

  private getPersonalisationApiUrlForGlobalId = (): string => {
    return `/api/personalisation/v2/getClient/globalId/${this.loggedInHeaders.globalId}?${personalisationApiParams}`;
  };

  // NB: Angular doesn't seem to have a type for http options:
  // https://stackoverflow.com/questions/56602725/why-doesnt-angular-provide-a-type-for-httpclients-options-paramter
  private getHttpOptions = (token?: PersonalisationToken) => {
    const headers: any = this.loggedInHeaders || {};
    if (token) {
      headers.Authorization = `Bearer ${token}` as PersonalisationToken;
      return {
        headers: new HttpHeaders(headers),
      };
    }
    return {
      headers: new HttpHeaders(headers),
    };
  };

  public mapFavoritesFundIds = (
    rawProducts: PersonalisationFavoriteData[]
  ): PersonalisationPersonalDataProduct[] => {
    const validFunds: Record<string, PersonalisationPersonalDataProduct> = {};
    for (const rawProduct of rawProducts) {
      if (rawProduct) {
        const [fundId, shareClassCode] = getFundIdAndShareclassCode(
          rawProduct.fundShareClassId
        );
        if (!fundId || !shareClassCode) {
          logger.warn(` Invalid oneTis code: ${rawProduct}`);
        } else if (
          !(
            this.siteConfigService.isActiveShareClass(
              rawProduct.fundShareClassId
            ) ||
            this.siteConfigService.isSoftLaunchShareClass(
              rawProduct.fundShareClassId
            )
          )
        ) {
          logger.warn(`Inactive share class: ${rawProduct}`);
        } else {
          validFunds[rawProduct.fundShareClassId] = {
            fundId: fundId as FundId,
            shareClassCode: shareClassCode as ShareClassCode,
            updatedOn: rawProduct.updatedOn,
            relationships: ['favorite'],
          };
        }
      }
    }
    return Object.values(validFunds);
  };

  private mapFundIds = (
    relationship: PersonalisationProductRelationship,
    globalId: GlobalId,
    validFunds: Record<string, PersonalisationPersonalDataProduct>,
    rawProducts: PersonalisationPersonalDataProductDto[]
  ): void => {
    let activeListUpdated = false;
    for (const rawProduct of rawProducts) {
      if (rawProduct?.oneTis) {
        if (
          this.firmConfig?.includeInvestedFunds &&
          relationship === USServicingComponentTypes.HELD
        ) {
          // held products for specific firms are always active
          activeListUpdated = this.siteConfigService.addFundToActiveList(
            rawProduct.oneTis,
            this.firmConfig.hasProductPageLinks
          );
        }
        const [fundId, shareClassCode] = getFundIdAndShareclassCode(
          rawProduct.oneTis
        );
        // check both fundId and shareClassCode are passed
        // TODO: this could add more checks in future
        if (!fundId || !shareClassCode) {
          logger.warn(
            `globalId: ${globalId}. Invalid oneTis code: ${rawProduct.oneTis}`
          );
        } else if (
          !(
            this.siteConfigService.isActiveShareClass(rawProduct.oneTis) ||
            this.siteConfigService.isSoftLaunchShareClass(rawProduct.oneTis)
          )
        ) {
          logger.warn(
            `globalId: ${globalId}. Inactive share class: ${rawProduct.oneTis}`
          );
        } else {
          validFunds[rawProduct.oneTis] = {
            fundId: fundId as FundId,
            shareClassCode: shareClassCode as ShareClassCode,
            relationships: [relationship],
            sortOrder: rawProduct?.sortOrder,
          };
        }
      }
    }
    if (activeListUpdated) {
      this.isActiveListUpdatedByFirm$.next(true);
    }
  };

  private mapPersonalData = (
    rawData: PersonalisationPersonalDataDto
  ): PersonalisationPersonalData => {
    const globalId: GlobalId = rawData.identifiers?.globalId;
    const subscribedDocuments: PersonalisationPersonalDataDocuments[] =
      this.mapSubscribedDocuments(rawData.subscribedDocuments) || [];

    return {
      name: rawData.name,
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      email: rawData.email,
      phoneExtension: rawData?.phoneExtension,
      phoneNumber: rawData?.phoneNumber,
      companyName: rawData?.companyName,
      advisorTeamId: rawData?.advisorTeamId,
      advisorTeamName: rawData?.advisorTeamName,
      clientType: rawData?.clientType,
      isDcLead: rawData.dcLeadFlag === 'Y',
      riaTerrId: rawData?.riaTerrId,
      firmGlobalId: rawData.firmGlobalId,
      parentFirmGlobalId: rawData?.parentFirmGlobalId,
      localSalesTeam: rawData?.localSalesTeam,
      isMarketRegistered: rawData?.marketRegistered === 'Y',
      dbrInfo: {
        dealerNumber: rawData?.dealerNumber,
        branchNumber: rawData?.branchNumber,
        repNumber: rawData?.repNumber,
      },
      ftcomRegistered: rawData.ftcomRegistered === 'Y',
      identifiers: {
        globalId,
        expressNumber: rawData.identifiers?.expressNumber,
      },
      subscribedDocuments,
      teamInvestedFundFlag: rawData.teamInvestedFundFlag === 'Y',
      hasPersonalHeldProducts: rawData.heldProducts?.length > 0,
      focusList: this.mapFavorites(rawData.focusList) || [],
      primarySalesPerson: rawData.primarySalesPerson,
      salesOffice: rawData.salesOffice,
    };
  };

  public isProfileActivated$ = (): Observable<boolean> =>
    this.personalData$.pipe(
      map(
        (personalData: PersonalisationPersonalData): boolean =>
          personalData.isMarketRegistered
      )
    );

  private mapFavorites(
    favorites: PersonalisationFavoriteDataDTO[]
  ): PersonalisationFavoriteData[] {
    return favorites?.map((data) => {
      return {
        ...data,
        fundShareClassId: data.tisShareclassCode,
        updatedOn: data.updatedon,
      };
    });
  }

  private mapProducts = ([
    data,
    isPopulated,
    firmInvestedFunds,
    firmSpecificFunds,
  ]: [
    PersonalisationPersonalData,
    boolean,
    PersonalisationPersonalDataProductDto[],
    boolean
  ]): PersonalisationPersonalDataWithProducts => {
    const validFunds: Record<string, PersonalisationPersonalDataProduct> = {};
    // Canadian hack
    if (data.salesOffice === 'Canada') {
    }
    this.mapFundIds(
      USServicingComponentTypes.HELD,
      data.identifiers.globalId,
      validFunds,
      this.rawData.heldProducts || []
    );

    // split out held funds only
    const validHeldFunds: Record<
      string,
      PersonalisationPersonalDataProduct
    > = {};
    this.mapFundIds(
      USServicingComponentTypes.HELD,
      data.identifiers.globalId,
      validHeldFunds,
      this.rawData.heldProducts || []
    );
    // for home office users we will show hofs products in held section
    if (this.isHomeOfficeUser(data.clientType)) {
      const hofsFunds: Record<string, PersonalisationPersonalDataProduct> = {};
      this.mapFundIds(
        USServicingComponentTypes.HELD,
        data.identifiers.globalId,
        hofsFunds,
        this.rawData.hofsProducts || []
      );
      return {
        ...data,
        products: Object.values(hofsFunds),
        heldProducts: Object.values(hofsFunds),
      };
    }
    if (
      firmInvestedFunds &&
      this.isRIAUser(data.localSalesTeam) &&
      !data.hasPersonalHeldProducts
    ) {
      const firmFunds: Record<string, PersonalisationPersonalDataProduct> = {};
      this.mapFundIds(
        USServicingComponentTypes.HELD,
        data.identifiers.globalId,
        firmFunds,
        firmInvestedFunds
      );
      return {
        ...data,
        products: Object.values(firmFunds),
        heldProducts: Object.values(validHeldFunds),
        firmProducts: Object.values(firmFunds),
      };
    }
    return {
      ...data,
      products: Object.values(validFunds),
      heldProducts: Object.values(validHeldFunds),
    };
  };

  private setUpPlatformProducts(
    personalisationFirmDataDto: PersonalisationFirmDataDto,
    personalisedData: PersonalisationPersonalData
  ) {
    if (personalisationFirmDataDto) {
      this.platformProducts = personalisationFirmDataDto.platformProducts;
      this.firmConfig = FirmConfig.getFirmConfigWithParentFirm(
        personalisedData.parentFirmGlobalId
      );
      this.siteConfigService.updateActiveListFunds(
        this.platformProducts,
        this.firmConfig.hasProductPageLinks
      );
      this.isActiveListUpdatedByFirm$.next(true);
    }
  }

  private getTranslatedDctermType(dctermsType: string): string {
    return dctermsType
      ? this.translateService.instant(`literature.${dctermsType}`)
      : undefined;
  }

  private getTokenPersonalData$ = (
    token: PersonalisationToken
  ): Observable<PersonalisationPersonalData> => {
    const httpRequest = this.loggedInHeaders // indicates we have an access token
      ? this.sendPersonalisationApiHttpReqForAccessToken(token)
      : this.sendPersonalisationApiHttpReqForIdentityToken(token);
    // data is combined to make sure siteConfig is loaded before next step
    return httpRequest.pipe(
      tap((data: PersonalisationPersonalDataDto): void => {
        logger.debug('get funds for token', token, data);
        this.rawData = data;
      }),
      map(this.mapPersonalData),
      catchError(this.handlePersonalisationApiError)
    );
  };

  private sendPersonalisationApiHttpReqForIdentityToken(
    token: PersonalisationToken
  ) {
    return this.http.get<PersonalisationPersonalDataDto>(
      this.getPersonalisationApiUrlForIdentityToken(token),
      this.getHttpOptions()
    );
  }

  private sendPersonalisationApiHttpReqForAccessToken(
    token: PersonalisationToken
  ) {
    return this.http.get<PersonalisationPersonalDataDto>(
      this.getPersonalisationApiUrlForGlobalId(),
      this.getHttpOptions(token)
    );
  }

  /**
   * Get Firm Invested Funds
   * @param globalId - GlobalId
   */
  private getFirmInvestedFunds$ = (
    globalId: GlobalId
  ): Observable<PersonalisationPersonalDataProductDto[]> => {
    return this.callFirm360$(globalId).pipe(
      map(
        (
          response: PersonalisationFirmDataDto
        ): PersonalisationPersonalDataProductDto[] => {
          if (response === null) {
            return [];
          }
          return response.heldProducts;
        }
      )
    );
  };

  /**
   * For use by the product availability guide - not used within PRC
   */
  public getProductAvailabilityData$(): Observable<PersonalisationFirmDataDto> {
    if (!this.productAvailabilityData$) {
      this.productAvailabilityData$ = this.personalData$.pipe(
        switchMap(
          (
            personalData: PersonalisationPersonalData
          ): Observable<PersonalisationFirmDataDto> => {
            return this.callFirm360$(
              personalData.firmGlobalId,
              PlatformQueryParam.ALL
            );
          }
        ),
        shareReplay(1)
      );
    }
    return this.productAvailabilityData$;
  }

  /**
   * make the call to firm 360
   * @param globalId - the firm or team globalid
   */
  private callFirm360$(
    globalId: GlobalId,
    platform?: PlatformQueryParam
  ): Observable<PersonalisationFirmDataDto> {
    return combineLatest([
      this.getIdentityToken$(),
      this.getOauthToken$(),
    ]).pipe(
      switchMap(
        ([identityToken, oauthToken]: [
          PersonalisationToken,
          PersonalisationToken
        ]): Observable<PersonalisationFirmDataDto> => {
          logger.debug(identityToken, oauthToken);
          if (oauthToken) {
            const platformQuery = platform ? `?platform=${platform}` : '';
            return this.http
              .get<PersonalisationFirmDataDto>(
                this.getFirmApiUrlForOauthToken(globalId, platform),
                this.getHttpOptions(oauthToken)
              )
              .pipe(catchError((error) => this.firmApiError(error)));
          } else {
            const platformQuery = platform ? `&platform=${platform}` : '';
            return this.http
              .get<PersonalisationFirmDataDto>(
                `/api/personalisation/firm/tokenId/${encodeURIComponent(
                  identityToken
                )}?globalId=${globalId}${platformQuery}`,
                this.getHttpOptions()
              )
              .pipe(catchError((error) => this.firmApiError(error)));
          }
        }
      )
    );
  }

  private getFirmApiUrlForOauthToken = (
    globalId: GlobalId,
    platform?: PlatformQueryParam
  ): string => {
    const platformQuery = platform ? `?platform=${platform}` : '';
    return this.appStateService.isAuth0Login()
      ? `/api/personalisation/v2/firm/globalId/${globalId}${platformQuery}`
      : `/api/personalisation/firm/globalId/${globalId}${platformQuery}`;
  };

  /**
   * checking the user is RIA using the local sales team
   * @param localSalesTeam - string
   */
  public isRIAUser(localSalesTeam: string): boolean {
    return localSalesTeam === PersonalisationLocalSalesTeam.RIA;
  }

  /**
   * checking the user is Home Office Contact using the client type
   * @param clientType - string
   */
  public isHomeOfficeUser(clientType: string): boolean {
    return clientType === PersonalisationLocalSalesTeam.HOMEOFFICE;
  }

  /**
   * checking the user is Edawrd Jones using the Parent Firm GlobalId
   * @param parentFirmGlobalId - string
   */
  public isEDJUser(parentFirmGlobalId: GlobalId): boolean {
    return parentFirmGlobalId === FirmConfig.EDWARD_JONES.parentFirmId;
  }

  /**
   * Firm API error handling
   * @param error - Error string
   */
  private firmApiError(error: string): Observable<null> {
    logger.debug('Firm API error:', error);
    return of(null);
  }

  // to get the firm specific funds for the firm and update active list
  public hasFirmSpecificFunds$(
    personalData: PersonalisationPersonalData
  ): Observable<boolean> {
    if (this.checkFirmSpecificFundsAvailable(personalData.parentFirmGlobalId)) {
      this.firmConfig = FirmConfig.getFirmConfigWithParentFirm(
        personalData.parentFirmGlobalId
      );
      return combineLatest([
        this.callFirm360$(
          personalData.firmGlobalId,
          PlatformQueryParam.PRODUCTS
        ),
        this.siteConfigService.getIsPopulated$(),
      ]).pipe(
        skipWhile(([firmSpecificProducts, populated]) => !populated),
        map(([firmSpecificProducts, populated]) => {
          this.setUpPlatformProducts(firmSpecificProducts, personalData);
          return true;
        })
      );
    }
    return of(false);
  }

  // to check the parentGlobalFirmId present in the firm specific id
  private checkFirmSpecificFundsAvailable = (
    parentFirmGlobalId: GlobalId
  ): boolean =>
    this.appStateService
      .getFirmSpecificFunds()
      .some((firmId: GlobalId) => firmId === parentFirmGlobalId);

  // to check active list of funds has been updated
  public getIsActiveListUpdatedByFirm$(): Observable<boolean> {
    return this.isActiveListUpdatedByFirm$.asObservable();
  }

  /**
   * Create dummy personalisation data for a firm when we have firm level identification
   */
  public setPersonalisationDataForFirm(firm: IFirmProfile) {
    this.storageService.getIdentityToken().then((token) => {
      if (!token || token === GENERIC_ID_TOKEN) {
        // dont call personalisation api for dummy user.
        const firmPersonalData: PersonalisationPersonalData = {
          identifiers: {
            globalId: '0' as GlobalId,
          },
          firmGlobalId: firm?.firmId,
          parentFirmGlobalId: firm?.parentFirmId,
        };
        this.personalData$.next(firmPersonalData);
        this.storageService.setIdentityToken(GENERIC_ID_TOKEN);
        // this.setFirmSpecificFunds(firmPersonalData) is called in getPersonalData$ observable it seams to be not required here.
      }
    });
  }

  public setLoggedInHeaders(value: ILoggedInHeaders) {
    this.loggedInHeaders = value;
    if (!this.loggedInHeaders.globalId) {
      logger.error(
        'No globalId set for user with expressNo: ' +
          this.loggedInHeaders.expressNo
      );
    }
  }
}
